import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './Projects.css';
import './Design.css';
import ProjectCard from "./ProjectCard";
import nt_1 from '../media/projects/NutriTiger_hp.png';
import mouse_1 from '../media/projects/mouse_1.png';
import roomly_1 from "../media/projects/roomly_1.png"
import home_1 from "../media/projects/home_1.png"

function Design() {
    const projects = [
        {
          image: nt_1,
          title: "NutriTiger",
          description: "A web app that provides nutritional data and tracking for Princeton dining hall foods.",
          projectid: "nutritiger"
        },
        {
          image: mouse_1,
          title: "Website redesign for Mouse",
          description: "Redesigned outdated pages on a nonprofit organization's website.",
          projectid: "mouse"
        },
        {
          image: roomly_1,
          title: "Roomly",
          description: "A roommate matching app for Princeton University.",
          projectid: "roomly"
        },
        {
          image: home_1,
          title: "My portfolio website",
          description: "Designed and developed this website to serve as my portfolio.",
          projectid: "portfolio"
        }
      ];

    return(
        <div className='project-card-container'>
            <h1 className='big-hi'>UX Design Work</h1>

            <div className="container mt-5">
            <div className="row">
                {projects.map((project, index) => (
                <div className="col-md-4 mb-4" key={index}>
                    <ProjectCard
                    image={project.image}
                    title={project.title}
                    description={project.description}
                    projectid={project.projectid}
                    />
                </div>
                ))}
            </div>
        </div>
        </div>
    );
}

export default Design;