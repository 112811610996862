import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ProjectCard.css"

const ProjectCard = ({ image, title, description, projectid }) => {
  return (
    <Link to={`/UX/${projectid}`} style={{ textDecoration: 'none' }}>
      <div className="card card-border">
        <img src={image} className="card-img-top card-img" alt={title} />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
