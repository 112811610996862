import React from 'react';
import './Home.css';
import Carousel from '../components/Carousel';

function Sketchbook() {
    return (
        <div>
            <h1 className='hi-3'>Sketchbook</h1>
            <div>
                <Carousel />
            </div>
        </div>
    );
}

export default Sketchbook;