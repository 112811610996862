import React from 'react';
import './Projects.css';
import BackArrow from './BackArrow.js'
import BackArrow2 from './BackArrow2.js'
import { useParams } from 'react-router-dom';
import nt_1 from '../media/projects/NutriTiger_hp.png'
import nt_2 from '../media/projects/NutriTiger_hp2.png'
import pa5_1 from '../media/projects/PA5_chart.png'
import pa5_2 from '../media/projects/PA5_chart2.png'
import s_1 from '../media/projects/Seam_1.png'
import s_2 from '../media/projects/Seam_2.png'
import ntsketch_1 from '../media/projects/ntsketch1.png'
import ntsketch_2 from '../media/projects/ntsketch2.png'
import ntsketch_3 from '../media/projects/ntsketch3.png'
import web1 from '../media/projects/web1.png'
import mouse1 from '../media/projects/mouse1.png'
import mouse2 from '../media/projects/mouse2.png'
import roomly1 from '../media/projects/roomly1.png'
import ntsketch4 from '../media/projects/ntsketch4.png'
import mousefig1 from '../media/projects/mousefig1.png'
import mousefig2 from '../media/projects/mousefig2.png'
import mousefig3 from '../media/projects/mousefig3.png'
import roomlyfig1 from '../media/projects/roomlyfig1.png'
import roomlyfig2 from '../media/projects/roomlyfig2.png'
import roomlyfig3 from '../media/projects/roomlyfig3.png'


const projectData = {
  nutritiger: {
    title: "NutriTiger UX",
    type: "Design",
    description: "NutrTiger is a full-stack web application developed in COS333 in a team of 5. Its purpose is to empower Princeton students to make informed food choices with nutritional insights, meal tracking, and macronutrient analysis. I was in charge of the frontend development-starting from wireframing to development.",
    problem: "One design challenge I faced was having to include a lot of information on some pages, and having to present that information to users in the simplest way possible. For example, ",
    process: "After sketching designs and creating wireframes on Figma, I met with three groups of users to critique the usability and navigatability of the app. These three user groups were athletes, students with dietary restrictions, and then just a 'normal' group of students. Taking their feedback, I improved the wireframing before coding it. Additionally, we met with the Usability lab at Princeton, where I learned a lot about accessibility standards with colors and fonts, which allowed me to make a few more changes to make my designs more accessible.",
    image: ntsketch_1,
  },
  mouse: {
    title: "Mouse",
    type: "Design",
    description: "Details about the website redesign project for Mouse.",
    image: "path/to/mouse-image",
  },
  roomly: {
    title: "Roomly",
    type: "Design",
    description: "Details about Roomly roommate matching app.",
    image: "",
  },
  portfolio: {
    title: "Portfolio",
    type: "Design",
    description: "This website was a personal project to learn React and to get more creative with web development. When designing my webpages, I prioritized navigation, accessibility, and conveying my unique style. Since this is a smaller-scale project, I sketched on paper.",
    problem: "My main challenge was to encapsulate both of my interests: computer science and design, such that it was evident to all visitors what areas that my projects focus on. Thus, I kept a very clear separation between my CS and UX design projects on the navigation bar and in the two buttons on the homepage. Additionally, I kept my website simple to encourage users to visit every page.",
    image: "../media/sketchbook/IMG_3511.JPG",
  },
  nutritiger1: {
    title: "NutriTiger CS",
  },
  imageclass: {
    title: "Multiclass Image Classifier",
  },
  seamcarver: {
    title: "Seam Carver",
  }
};

const ProjectDetail = () => {
  const { projectid } = useParams();
  const project = projectData[projectid];

  if (!project) {
    return <h1>Project not found</h1>;
  }
  
  const projTitle = project.title;

  if (projTitle === "NutriTiger UX") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
        <BackArrow2/>
        <h3 className='p-subtitle'>NutriTiger</h3>

        <strong className='p-strong'>A full-stack web app. Figma, Wireframing, Prototyping, HTML/CSS. </strong> <br/>
        <img src={nt_1} className='p-image2' alt=''/>
        <img src={nt_2} className='p-image2' alt=''/>

        <p className='p-description'>
        NutriTiger is a full-stack web application developed in COS333 in a team of 5. Its purpose 
        is to empower Princeton students to make informed food choices with nutritional insights, meal tracking, 
        and macronutrient analysis.  
        <br></br><br>
        </br>I was in charge of the frontend development-starting from wireframing to development.
        </p>

        <strong className='p-strong'>The problem</strong>
        <p className='p-description'>Princeton does not provide any convenient and accessible ways to see the ingredients and macros of dining hall foods. 
           The existing website to access this data was so obscure and hard to navigate that most students I surveyed were unaware that it even existed.
           Thus, we developed NutriTiger with UX in mind.
        </p>

        <strong className='p-strong'>The process</strong>
        <p className='p-description'>
            <strong>User Research</strong><br></br>
            <strong>Stage 1:</strong> I began by surveying students, to see what features of the app they would like.
            <br></br>
            <strong>Stage 2:</strong> Later, after developing a rough prototype, I met with groups of users to access the usability 
            of the app, and observing how easily they were able to understand and use each feature.
            <br></br>
            <strong>Stage 3:</strong> Meeting with experts at the User Experience LAB at Princeton to get feedback on my prototype.
            I got advice on accessibility standards (colors, buttons, font sizes) and navigatability.
            <br></br>
            <br></br>
            <strong>Design</strong>
            <br></br>
            I opted to adhere to the branding trends that I observed in many other popular student-made applications at Princeton.
            This meant creating a more casual, fun feel to the website. This influenced my color choice and logo design. Additionally,
            I chose to include accents of orange to compliment the Princeton-influenced branding.
            <br></br>
            <br></br>
            Here are some pictures from my design process:
            <br></br>
        </p>

        <strong className='p-strong'>Brainstorming</strong>
        <br></br>
        <br></br>
        <img src={ntsketch_1} alt="Description" className='process-img' />
        <img src={ntsketch_2} alt="Description" className='process-img' />
        <img src={ntsketch4} alt="Description" className='process-img' />
        <br></br>
        <br></br>
        
        <strong className='p-strong'>The process</strong>
        <br></br>
        <br></br>
        <img src={ntsketch_3} alt="Description" className='process-img' />
        <br></br>
    </div>
    </div>
    </div>
    );
  }

  if (projTitle === "Mouse") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
        <BackArrow2/>
        <h3 className='p-subtitle'>Mouse website redesign</h3>

        <strong className='p-strong'>Redesigned outdated pages on a nonprofit organization's website. Figma, wireframing, HTML/CSS.</strong> <br/>

        <p className='p-description'>
        Mouse is a nonprofit organization in NYC that works to provide Computer Science learning courses to public 
        schools across NYC that may not have access to such STEM education. 
        <br></br><br>
        </br>I interned at Mouse as a UI/UX Development Intern in the summer of 2024.
        </p>

        <strong className='p-strong'>The problem</strong>
        <p className='p-description'>Due to Mouse being a smaller organization, their website had not been updated in 
            years. Thus, their website had a lot of navigation issues and outdated information. This was impacting their outreach
            efforts, since their website was not representative of what the org currently does.
        </p>

        <strong className='p-strong'>The process</strong>
        <p className='p-description'>
            <strong>Research</strong><br></br>
            <strong>Stage 1:</strong> I began my redesign process by exploring Mouse's entire website to assess how I, as a new user, was able to 
            navigate the website and get the information I wanted. I took notes on different routes that were possible and confusions that I had.
            <br></br>
            <strong>Stage 2:</strong> I researched similar educational nonprofits online to study their websites. This helped me narrow down what the essential
            features of nonprofit websites are (ex. a dedicated button for donations).
            <br></br>
            <strong>Stage 3:</strong> Reaching out to members of the Mouse team to learn more about what exactly Mouse does, and what kind of image
            they wanted to present to the audience online. This step was heavily influenced by my experience in marketing.
            <br></br>
            <br></br>
            <strong>Design</strong>
            <br></br>
            I was not changing the entire website during my internship. Rather, I was updating and redesigning the most important pages, which was
            for 'Design League', Mouse's most important program of the year. Thus, I brainstormed designs that were more modern than Mouse's current
            webpage designs, but ensured that they would not stick out too much from the other webpages. This meant keeping colors, placing, buttons, consistent.
            <br></br>
            <br></br>
            Here are some pictures from my design process:
            <br></br>
            <br></br>
        </p>

        <strong className='p-strong'>Initial notes</strong>
            <br></br>
            <img src={mouse1} alt="Description" className='process-img' />
            <img src={mouse2} alt="Description" className='process-img' />
            <br></br>
            <br></br>
        <strong className='p-strong'>Figma Wireframes</strong>
            <br></br>
            <br></br>
            <img src={mousefig1} alt="Description" className='figma-img' />
            <img src={mousefig2} alt="Description" className='figma-img' />
            <img src={mousefig3} alt="Description" className='figma-img' />
    </div>
    </div>
    </div>
    );
  }

  if (projTitle === "Roomly") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
        <BackArrow2/>
        <h3 className='p-subtitle'>Roomly</h3>

        <strong className='p-strong'>A full-stack web app. Figma, React, HTML/CSS, wireframing.</strong> <br/>

        <p className='p-description'>
        Roomly is a full-stack web application that I am currently in the process of developing in a team of 3 students.
        It aims to provide easy and safe roommate searching for summer internship housing. 
        <br></br><br>
        </br>I am currently working on the development and design of Roomly's frontend.
        </p>

        <strong className='p-strong'>The problem</strong>
        <p className='p-description'>Students are often all over the country, or world, for their summer internships.
            The email listservs are often clogged with students inquiring about anyone who would be interested in rooming with them
            over the summer in certain locations, and it is often hard to find other people who will be in the area. Thus, we decided
            to make this app, which will allow students to make posts advertising a search for roommates or a sublet they might be doing.
        </p>

        <strong className='p-strong'>The process</strong>
        <p className='p-description'>
            <strong>Research</strong><br></br>
            <strong>Stage 1:</strong> Our initial research step was to search through our email inboxes and see what students were advertising when looking
            for roommates, and how often this occurred. We noted that there was a sharp increase during the end of the academic year, right before the summer. 
            This confirmed that this app would help solve a need that Princeton students have.
            <br></br>
            <strong>Stage 2:</strong> Our next stage was to send out a survey to students to see what features they would want to see in this application.
            Additionally, we met with some of these users who agreed to be interviewed, and asked them about their experiences searching for a roommate, and also what would be most helpful.
            <br></br>
            <br></br>
            <strong>Design</strong>
            <br></br>
            Our goal is for Roomly to be used at other schools as well once it is fully developed. Thus, we opted for a neutral color scheme,
            focusing on neutral colors with some accents of purple. We aimed to make its identity distinct from Princeton's usual orange and black branding.
            <br></br>
            <br></br>
            Here are some pictures from my design process:
            <br></br>
        </p>

        <strong className='p-strong'>Making the logo</strong>
        <br></br>
        <img src={roomly1} alt="Description" className='process-img' />
        <br></br>
        <br></br>

        <strong className='p-strong'>Wireframes</strong>
        <br></br>
        <br></br>
        <img src={roomlyfig1} alt="Description" className='figma-img' />
        <img src={roomlyfig2} alt="Description" className='figma-img' />
        <img src={roomlyfig3} alt="Description" className='figma-img' />

    </div>
    </div>
    </div>
    );
  }

  if (projTitle === "Portfolio") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
        <BackArrow2/>
        <h3 className='p-subtitle'>My Portfolio website</h3>

        <strong className='p-strong'>This website. React, Python, HTML/CSS, JavaScript.</strong> <br/>

        <p className='p-description'>
        I wanted to create a project that shows both my technical and design skills. What better way to do that then
        to create my own website from scratch?
        </p>

        <strong className='p-strong'>THE PROCESS</strong>
        <p className='p-description'>
            I taught myself how to use React and how to work with custom domain names.
            I had a lot of fun playing around with colors and branding to best represent my unique style and creativity.

            <br></br>
            <br></br>
            Here are some pictures from my design process:
            <br></br>
            <img src={web1} alt="Description" className='process-img' />
        </p>
    </div>
    </div>
    </div>
    );
  }

  if (projTitle === "NutriTiger CS") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
        <BackArrow/>
        <h3 className='p-subtitle'>NutriTiger - 2024</h3>

        <strong className='p-strong'>A full-stack web app. Python, HTML/CSS, JavaScript.</strong> <br/>
        <img src={nt_1} className='p-image2' alt=''/>
        <img src={nt_2} className='p-image2' alt=''/>

        <p className='p-description'>
            NutriTiger is a web app for Princeton Students that aims
            to conveniently provide nutritional data for the Princeton dining hall foods.
            It also provides meal logging and macronutrient tracking over time. NutriTiger was
            created in a team of 5 for COS 333.
        </p>

        <strong className='p-strong'>Features:</strong>
        <ul className='p-description'>
            <li>User authentication to only recognize Princeton email accounts.</li>
            <li>Usage of cookies to identify first-time or returning users.</li>
            <li>An XML web scraper to get the nutritional information for the foods.</li>
            <li>Data stored in MongoDB database, optimized for storing and retrieving data.</li>
        </ul>
    </div>
    </div>
    </div>
    );
  }

  if (projTitle === "NutriTiger") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
            <BackArrow2/>
            <h3 className='p-subtitle'>NutriTiger</h3>
            <strong className='p-strong'>Figma. Wireframing. Prototyping. UI/UX.</strong><br/>
            <img src={s_1} className='p-image2' alt=''/>
            <img src={s_2} className='p-image2' alt=''/>

            <p className='p-description'> A content-aware image resizing algorithm by identifying the 
            least significant pathways in the image pixels, utilizing Dijkstra’s shortest path algorithm
            and dynamic programming.
            </p>
        </div>
        </div>
        </div>
    );
  }

  if (projTitle === "Multiclass Image Classifier") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
            <BackArrow/>
            <h3 className='p-subtitle'>Multiclass Image Classifier - 2024</h3>
            <strong className='p-strong'>Machine Learning. Python, using the PyTorch package.</strong> <br/>
            
            <div className='p-img-container'>
            <div className='p-imgbox'>
            <img src={pa5_1} className='p-image' alt=''/>
            </div>
            <img src={pa5_2} className='p-image2' alt=''/>
            </div>
            <p className='p-description'> Designed and trained a classifier based on convolutional neural networks
                (CNNs) to classify images of handwritten numerical digits. Obtained a prediction accuracy of 99.02% on test digit sets. 
            </p>
        </div>
        </div>
        </div>
    );
  }

  if (projTitle === "Seam Carver") {
    return (
        <div className='container'>
        <div className='p-container'>
        <div className='project'>
            <BackArrow/>
            <h3 className='p-subtitle'>Seam Carving - 2023</h3>
            <strong className='p-strong'>Data Structures & Algorithms. Java.</strong><br/>

            <img src={s_1} className='p-image2' alt=''/>
            <img src={s_2} className='p-image2' alt=''/>

            <p className='p-description'> A content-aware image resizing algorithm by identifying the 
            least significant pathways in the image pixels, utilizing Dijkstra’s shortest path algorithm
            and dynamic programming.
            </p>
        </div>
        </div>
        </div>
    );
  }

};

export default ProjectDetail;
