import React from 'react';

const EmailLink = () => {
    return (
        <div>
            <span>Email: </span>
            <a href='mailto:stephanieoh@princeton.edu'>stephanieoh@princeton.edu</a>
        </div>
    );
};

export default EmailLink;