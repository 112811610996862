import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Contact from './pages/Contact';
import Sketchbook from './pages/Sketchbook';
import About from './pages/About';
import Projects from './pages/CSProjects';
import ProjectDetail from './pages/ProjectDetails';
import Design from './pages/Design';

function App() {
  return (
    <Router>
      <div>
        <Header />
        
        <Routes>
          <Route exact path='/' Component={Home}></Route>
          <Route exact path='/about' Component={About}></Route>
          <Route exact path='/CSprojects' Component={Projects}></Route>
          <Route exact path='/CSprojects:projectid' Component={ProjectDetail}></Route>
          <Route exact path='/UX' Component={Design}></Route>
          <Route exact path='/UX/:projectid' Component={ProjectDetail}></Route>
          <Route exact path='/sketchbook' Component={Sketchbook}></Route>
          <Route exact path='/contact' Component={Contact}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;