import React from 'react';
import './Home.css';
import headshot2 from '../media/26headshot.jpg';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/CSprojects');
    };

    const handleButtonClick1 = () => {
        navigate('/UX');
    };

    return (
        <div className='background'>
            <div className='container'>
                
                <img src={headshot2} className='headshot' alt='headshot'/>

                <div className='home-title'>
                    <h1 className='hi-2'>Hi! I'm Stephanie.</h1> <br />
            
                    <h3 className='subtitle'>I am a junior at Princeton University majoring in 
                        <strong> Computer Science</strong> with a minor in Technology & Society.</h3>

                        <br/><br/>

                        <h3 className='subtitle'>I'm interested in <strong>CS + Design.</strong></h3>

                    <div className="about-btn-container">
                    <Button className='about-btn' variant="outline-primary" onClick={handleButtonClick}>CS Projects</Button>
                    <Button className='about-btn' variant="outline-primary" onClick={handleButtonClick1}>UX Work</Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Home;