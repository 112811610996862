import React from 'react';
import EmailLink from '../components/EmailLink';

function Contact() {
    return (
        <>        
        <h1 className='hi'>Contact</h1>
        <br />
        
        <h4 className='subtitle'><EmailLink /></h4> 
        <br />

        <h4 className='subtitle'>LinkedIn: <a href='https://www.linkedin.com/in/stephanie-oh' rel="noreferrer noopener">www.linkedin.com/in/stephanie-oh</a></h4>
        </>
    );
}

export default Contact;