import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll(require.context('../media/sketchbook/', false, /\.(JPG)$/));
  console.log(images)

  const Carousel = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
  
    return (
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} className='carousel-image' loading="lazy"/>
          </div>
        ))}
      </Slider>
    );
  };
  
  export default Carousel;