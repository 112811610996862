import { Link } from "react-router-dom";

const BackArrow = () => (
  <Link to="/CSProjects" style={{ textDecoration: 'none', color: 'navy', display: 'flex', alignItems: 'center' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32" // Adjust width for longer arrow
      height="16" // Adjust height as needed
      fill="navy"
      className="bi bi-arrow-left"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
      />
    </svg>
    <span style={{ marginLeft: '5px' }}>back</span> {/* Add spacing between arrow and text */}
  </Link>
);

export default BackArrow;
